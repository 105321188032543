<template>
  <div>
    <card-expansion class="mb-2" title="Instruksi Kerja" no-padding>
      <template slot="action">
        <div style="max-width: 200px;">
          <v-text-field v-model="datatable.search" outlined hide-details placeholder="Search" dense></v-text-field>
        </div>
      </template>
      <v-data-table :headers="datatable.headers" :items="datatable.items" item-key="filename" :search="datatable.search" dense>
        <template v-slot:[`item.file_name`]="{ item }">
          <a href="javascript:;" @click="getDownload(item)">{{ item.file_name }}</a>
        </template>
      </v-data-table>
    </card-expansion>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datatable: {
        search: '',
        headers: [
          { text: 'File', value: 'file_name' },
        ],
        items: []
      }
    }
  },
  methods: {
    getData() {
      this.datatable.loading = true;
      this.$axios
        .get("ik/data")
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.items = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    // Download File Via Axios
    getDownload(item) {
      const uri = "ik/download/"
      this.$axios
        .get(uri, {
          params: {
            file_name: item.file_name
          },
          responseType: 'blob'
        })
        .then((res) => {
          this.downloadFromBlob(res.data, item.file_name);
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData()
  }
}
</script>