<template>
  <div>
    <v-container>
      <page-title title="Dashboard"></page-title>

      <stat1 v-if="modulePermission('dashboard', 'stat1')" class="mb-2"></stat1>

      <card-expansion class="mb-2" title="Waiting Approval" no-padding v-if="show_waiting_approval">
        <v-tabs v-model="waiting_approval_tab" class="py-0 my-0">
          <v-tab v-if="modulePermission('dashboard', 'return-material-approval')">
            <v-badge color="green" v-if="approvalCount.rmp > 0" :content="approvalCount.rmp">Return Material &nbsp;</v-badge>
            <span v-else>Return Material</span>
          </v-tab>
          <v-tab v-if="modulePermission('dashboard', 'berita-acara-waiting-approval')">
            <v-badge color="green" v-if="approvalCount.berita_acara > 0" :content="approvalCount.berita_acara">Berita Acara &nbsp;</v-badge>
            <span v-else>Berita Acara</span>
          </v-tab>
          <v-tab v-if="modulePermission('dashboard', 'mjo-waiting-approval')">
            <v-badge color="green" v-if="approvalCount.mjo > 0" :content="approvalCount.mjo">MJO &nbsp;</v-badge>
            <span v-else>MJO</span>
          </v-tab>
          <v-tab v-if="modulePermission('dashboard', 'cpar-waiting-approval')">
            <v-badge color="green" v-if="approvalCount.cpar > 0" :content="approvalCount.cpar">CAPA &nbsp;</v-badge>
            <span v-else>CAPA</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="waiting_approval_tab">
          <v-tab-item v-if="modulePermission('dashboard', 'return-material-waiting-approval')">
            <rmp-approval class="mb-2"></rmp-approval>
          </v-tab-item>
          <v-tab-item v-if="modulePermission('dashboard', 'berita-acara-waiting-approval')">
            <berita-acara-approval class="mb-2"></berita-acara-approval>
          </v-tab-item>
          <v-tab-item v-if="modulePermission('dashboard', 'mjo-waiting-approval')">
            <mjo-approval class="mb-2"></mjo-approval>
          </v-tab-item>
          <v-tab-item v-if="modulePermission('dashboard', 'cpar-waiting-approval')">
            <cpar-approval class="mb-2" ref="refCparWaitingApproval" @datatable="(val) => { datatable_cpar = val }"></cpar-approval>
          </v-tab-item>
        </v-tabs-items>
      </card-expansion>

      <v-row>
        <v-col lg="6">
          <ListIK></ListIK>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Stat1 from './components/Stat1.vue'
import RmpApproval from './components/RmpApproval.vue';
import BeritaAcaraApproval from './components/BeritaAcaraApproval.vue';
import MjoApproval from './components/MjoApproval.vue';
import CparApproval from './components/CparApproval.vue';
import ListIK from './components/ListIK.vue';
export default {
  name: "Dashboard",
  components: {
    Stat1,
    RmpApproval,
    BeritaAcaraApproval,
    MjoApproval,
    CparApproval,
    ListIK,
  },
  data() {
    return {
      waiting_approval_tab: this.$store.state.activeTab['dashboard.waiting-approval'],
      approvalCount: {},
    }
  },
  watch: {
    waiting_approval_tab(newVal) {
      this.$store.state.activeTab['dashboard.waiting-approval'] = newVal
    }
  },
  computed: {
    show_waiting_approval() {
      if (this.modulePermission('dashboard', 'return-material-waiting-approval')) return true;
      if (this.modulePermission('dashboard', 'berita-acara-waiting-approval')) return true;
      if (this.modulePermission('dashboard', 'mjo-waiting-approval')) return true;
      if (this.modulePermission('dashboard', 'cpar-waiting-approval')) return true;
      return false;
    },
  },
  methods: {
    async getApprovalCount() {
      var config = {
        cacheConfig: false,
      };
      await this.$axios
        .get("dashboard/approval-count", config)
        .then((res) => {
          this.approvalCount = res.data['data'];
        })
        .catch((error) => {
          console.log(error);
        });

      setTimeout(() => {
        this.getApprovalCount()
      }, 1000 * 60 * 5) // 5 Menit sekali
    }
  },
  mounted() {
    this.requiredLogin();

    this.getApprovalCount();
  },
};
</script>